import React from 'react'

import logo from '../../assets/img/6.png'

const CachacasImage = () => (
  <div className='default-image'>
    <img src={logo}  alt='Cachacas' style={{ width: '100%' }} />
  </div>
)

export default CachacasImage