import React from 'react'

import logo from '../../assets/img/4.png'

const KidsImage = () => (
  <div className='default-image'>
    <img src={logo}  alt='Kids' style={{ width: '100%' }} />
  </div>
)

export default KidsImage