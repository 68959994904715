import React, { useState, useEffect } from 'react';
import api from '../services/api.js';

const FecharMesa = ({ total }) => {
    const [cpfCnpj, setCpfCnpj] = useState('');
    const [pagamentos, setPagamentos] = useState([]);
    const [troco, setTroco] = useState([]);
    const [saldo, setSaldo] = useState([]);
    const [isOpenModalReceberMesa, setIsOpenModalReceberMesa] = useState(false);
    const [isOpenModalReceberMesaPendente, setIsOpenModalReceberMesaPendente] = useState(false);
    const [isOpenModalFecharMesa, setIsConfirmacaoModalFecharMesa] = useState(false);    
    const [isOpenModalReabrirMesa, setIsConfirmacaoModalReabrirMesa] = useState(false);
    const [isOpenModalMotivo, setIsOpenModalMotivo] = useState(false);
    const [isOpenModalFaltaAdicionarPagamento, setIsFaltaAdicionarPagamento] = useState(false);
    const [formaPagamento, setFormaPagamento] = useState('');
    const [valorPagamento, setValorPagamento] = useState('');
    const [numeroMesa, setNumeroMesa] = useState('');

    useEffect(() => {
        const mesaFromStorage = localStorage.getItem('numeroMesa');
        setNumeroMesa(mesaFromStorage);
    }, []);
    
    const handleCpfCnpjChange = (e) => {
        setCpfCnpj(e.target.value);
    };

    const reabrirMesa = async () => {
        try {
            await api.post('mesa-pedido/reabrir', { numero_mesa: numeroMesa });
            localStorage.removeItem('produtos');
            localStorage.removeItem('idPedido');
            localStorage.removeItem('numeroMesa');
            localStorage.removeItem('idArquivo');
            localStorage.removeItem('status');
             openModalReabrirMesa(true);
            // setIsOpenModalReabrirMesa(false);            
        } catch (error) {
            console.error('Erro ao reabrir a mesa:', error);
        }
    };

    const adicionarPagamento = () => {
        if (formaPagamento && valorPagamento) {
            if (parseFloat(valorPagamento) === 0) {
                alert('Por favor, insira um valor diferente de zero.');
                return; 
            }
    
            const novoPagamento = { forma: formaPagamento, valor: parseFloat(valorPagamento) };
            setPagamentos([...pagamentos, novoPagamento]);
            setFormaPagamento('');
            setValorPagamento('');
            const elemento = document.querySelector('.content-botoes');
            if (elemento) elemento.style.display = 'none';
        } else {
            alert('Por favor, selecione a forma de pagamento e insira o valor.');
        }
    };
    
    useEffect(() => {
        const calcularTotaisFechamento = () => {
            const totalPago = pagamentos.reduce((acc, pagamento) => acc + pagamento.valor, 0);
            let saldo = total - totalPago;
            const troco = saldo < 0 ? Math.abs(saldo) : 0;

            if (totalPago > total) {
                saldo = 0;
            }

            const pagoElement = document.querySelector('.valores.pago');
            const saldoElement = document.querySelector('.valores.saldo');
            const trocoElement = document.querySelector('.valores.troco');

            if (pagoElement) pagoElement.innerText = totalPago.toFixed(2);
            if (saldoElement) saldoElement.innerText = saldo.toFixed(2);
            if (trocoElement) trocoElement.innerText = troco.toFixed(2);

            if (saldoElement) saldoElement.style.color = saldo > 0 ? '#9C0000' : 'inherit';
            if (trocoElement) trocoElement.style.color = troco > 0 ? '#3A45A9' : 'inherit';
            
            setSaldo(saldo.toFixed(2));
            setTroco(troco.toFixed(2));
            setValorPagamento(saldo.toFixed(2));            
        };

        calcularTotaisFechamento();
    }, [pagamentos, total]);


    const submitCpfCnpj = (value) => {        
        const limpaValue = value.replace(/\D/g, '');
            
        const isCpf = limpaValue.length === 11;
        const isCnpj = limpaValue.length === 14;
    
        if (isCpf || isCnpj ) {
            return true;
        } else {
            alert("Por favor, insira um CPF ou CNPJ válido.");
            return false;
        }
    };
    
    
    const receberMesa = () => {
        if (formaPagamento && valorPagamento) {
            if (parseFloat(valorPagamento) !== 0) {
                adicionarPagamento();
                setIsFaltaAdicionarPagamento(true);
                return;
            }
        }
        /* eslint-disable eqeqeq */
        if (cpfCnpj && cpfCnpj != 999){
            if (submitCpfCnpj(cpfCnpj)){
                if (saldo > 0 ){
                    setIsOpenModalReceberMesaPendente(true);
                }else{
                    setIsOpenModalReceberMesa(true);
                }                
            }
        }else {        
            if (saldo > 0 ){
                setIsOpenModalReceberMesaPendente(true);
            }else{
                setIsOpenModalReceberMesa(true);
            }   
        }     
    };
        /* eslint-enable eqeqeq */
        
    const receberMotivo = () => {
        setIsOpenModalMotivo(true);
        setIsOpenModalReceberMesa(false);
    };

    const removerPagamento = (index) => {
        const newPagamentos = [...pagamentos];
        newPagamentos.splice(index, 1);
        setPagamentos(newPagamentos);
    };

    const openModalFecharMesa = async () => {
        try {
            const pagamentosFormatados = pagamentos.map(pagamento => ({                
                forma: pagamento.forma,
                valor: pagamento.valor
            }));
    
            if (troco !== "0.00") {                
                pagamentosFormatados.push({                    
                    forma: 'Troco',
                    valor: parseFloat(troco)
                });
            }

            if (saldo !== "0.00") {                
                pagamentosFormatados.push({                    
                    forma: 'Saldo',
                    valor: parseFloat(saldo)
                });
            }

            if (total === "0.00") {                
                pagamentosFormatados.push({                    
                    forma: 'Mesa Zerada',
                    valor: parseFloat(saldo)
                });
            }
            const response = await api.post('mesa-pedido/pagamentos', {pedido_id: localStorage.getItem('idPedido'), arquivo_id: localStorage.getItem('idArquivo'), quem_fechou: localStorage.getItem('loginId'), cpf_cnpj: cpfCnpj, tipo: 'M', pagamentos: pagamentosFormatados });
            console.log('Resposta da API:', response.data);
                        
            localStorage.removeItem('produtos');
            localStorage.removeItem('idPedido');
            localStorage.removeItem('numeroMesa');
            localStorage.removeItem('idArquivo');
            localStorage.removeItem('status');

            setIsOpenModalReceberMesa(false);
            setIsConfirmacaoModalFecharMesa(true);            
        } catch (error) {
            console.error('Erro ao fechar a mesa:', error);
        }
    };

    const openModalReabrirMesa = () => {
        setIsOpenModalMotivo(false);
        setIsConfirmacaoModalReabrirMesa(true);
    };

    const ok = () => {
        window.location.href = '/';
    };

    return (
        <div className='fecha-mesa'>
            <span>Digite CPF ou CNPJ:</span>
            <input
                id="cpf_cnpj"
                type="text" 
                inputMode="numeric"
                maxLength={15}
                value={cpfCnpj}
                onChange={handleCpfCnpjChange}
            />
            <table>
                <thead>
                    <tr>
                        <th>Forma de pagamento</th>
                        <th>Valor com desconto</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <select
                                name="motivo"
                                id="motivo"
                                value={formaPagamento}
                                onChange={(e) => setFormaPagamento(e.target.value)}
                            >
                                <option value="" disabled>Selecione</option>
                                <option value="Dinheiro">Dinheiro</option>
                                <option value="Credito">Crédito</option>
                                <option value="Pix">Pix</option>
                                <option value="Débito">Débito</option>
                                <option value="Outros">Outros</option>
                            </select>
                        </td>
                        <td>
                            <input
                                type="number"
                                value={valorPagamento}
                                onChange={(e) => setValorPagamento(e.target.value)}
                                onClick={(e) => e.target.select()}
                            />
                        </td>
                        <td>
                        <div className='adicionar'>
                            <button onClick={adicionarPagamento}>+</button>
                        </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            
            {pagamentos.length > 0 && (
                <div className='formas-pagamento'>
                    <table id='pagamentos'>
                        <thead>
                            <tr>
                                <th className='bandeiras'></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {pagamentos.map((pagamento, index) => (
                                <tr key={index}>
                                    <td>{pagamento.forma}</td>
                                    <td className='valores'>{pagamento.valor.toFixed(2)}</td>
                                    <td><button className='remover' onClick={() => removerPagamento(index)}>X</button></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
            <div className='totais'>
                <div>
                    <div>Total pago:</div>
                    <div>Troco:</div>
                    <div>Saldo:</div>
                </div>
                <div>
                    <div className='valores pago'>0,00</div>
                    <div className='valores troco'>0,00</div>
                    <div className='valores saldo'>{total}</div>
                </div>
            </div>
            <div className='content-fechar-mesa'>
                <button onClick={receberMotivo}>Reabrir mesa</button>
                <button onClick={receberMesa}>Receber</button>
            </div>
            {isOpenModalReceberMesaPendente && (
                <div className='content-modal'>
                    <div className='modal-content'>
                        <div className='icon-alert'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="92" height="92" viewBox="0 0 92 92" fill="none">
                            <path d="M46 0C36.9021 0 28.0084 2.69785 20.4438 7.7524C12.8791 12.8069 6.98319 19.9912 3.50156 28.3966C0.0199311 36.802 -0.891022 46.051 0.883898 54.9741C2.65882 63.8973 7.03989 72.0937 13.4731 78.5269C19.9063 84.9601 28.1027 89.3412 37.0259 91.1161C45.949 92.891 55.198 91.9801 63.6034 88.4984C72.0088 85.0168 79.1931 79.1209 84.2476 71.5562C89.3021 63.9916 92 55.0979 92 46C92 39.9592 90.8102 33.9775 88.4985 28.3966C86.1867 22.8156 82.7984 17.7446 78.5269 13.4731C74.2554 9.20159 69.1844 5.81326 63.6034 3.50154C58.0225 1.18983 52.0408 0 46 0ZM46 82.8C38.7217 82.8 31.6068 80.6417 25.555 76.5981C19.5033 72.5544 14.7866 66.8071 12.0013 60.0827C9.21595 53.3584 8.48719 45.9592 9.90712 38.8207C11.3271 31.6822 14.8319 25.125 19.9785 19.9785C25.1251 14.8319 31.6822 11.327 38.8207 9.9071C45.9592 8.48716 53.3584 9.21593 60.0828 12.0012C66.8071 14.7865 72.5544 19.5033 76.5981 25.555C80.6417 31.6067 82.8 38.7216 82.8 46C82.8 55.76 78.9229 65.1202 72.0215 72.0215C65.1202 78.9229 55.76 82.8 46 82.8Z" fill="black"/>
                            <path d="M45.9999 69C48.5404 69 50.5999 66.9405 50.5999 64.4C50.5999 61.8595 48.5404 59.8 45.9999 59.8C43.4594 59.8 41.3999 61.8595 41.3999 64.4C41.3999 66.9405 43.4594 69 45.9999 69Z" fill="black"/>
                            <path d="M45.9999 23C44.7799 23 43.6099 23.4846 42.7472 24.3473C41.8845 25.21 41.3999 26.38 41.3999 27.6V50.6C41.3999 51.82 41.8845 52.99 42.7472 53.8527C43.6099 54.7154 44.7799 55.2 45.9999 55.2C47.2199 55.2 48.3899 54.7154 49.2526 53.8527C50.1153 52.99 50.5999 51.82 50.5999 50.6V27.6C50.5999 26.38 50.1153 25.21 49.2526 24.3473C48.3899 23.4846 47.2199 23 45.9999 23Z" fill="black"/>
                            </svg>
                        </div>
                        <h3>Valores pendentes</h3>
                        <p>Deseja confirmar o pagamento com valor(es) pendente(s)?</p>
                        <div className='botoes'>
                            <button onClick={() => setIsOpenModalReceberMesaPendente(false)}>Cancelar</button>
                            <button className='confirmar' onClick={openModalFecharMesa}>Confirmar</button>
                        </div>
                    </div>
                </div>
            )}
            {isOpenModalReceberMesa && (
                <div className='content-modal'>
                    <div className='modal-content'>
                        <h3>Confirma Recebimento?</h3>                        

                        <div className='botoes'>
                            <button onClick={() => setIsOpenModalReceberMesa(false)}>Cancelar</button>
                            <button className='confirmar' onClick={openModalFecharMesa}>Confirmar</button>
                        </div>
                    </div>
                </div>
            )}
            {isOpenModalMotivo && (
                <div className='content-modal'>
                    <div className='modal-content'>
                    <h3>! Deseja reabrir a mesa?</h3>
                        {/*
                        <div className='obs'>
                            <textarea name="obs" id="obs" cols="30" rows="10"></textarea>
                        </div> */}
                        <div className='botoes'>
                            <button onClick={() => setIsOpenModalMotivo(false)}>Cancelar</button>
                            <button className='confirmar'onClick={reabrirMesa}>Confirmar</button>
                        </div>
                    </div>
                </div>
            )}
            {isOpenModalReabrirMesa && (
                <div className='content-modal'>
                    <div className='modal-content'>
                        <h3>Mesa reaberta com sucesso</h3>
                        <div className='botoes'>
                            <button className='confirmar' onClick={ok}>Ok</button>
                        </div>
                    </div>
                </div>
            )}
            {isOpenModalFecharMesa && (
                <div className='content-modal'>
                    <div className='modal-content'>
                        <h3>Mesa fechada com sucesso</h3>
                        <div className='botoes'>
                            <button className='confirmar' onClick={ok}>Ok</button>
                        </div>
                    </div>
                </div>
            )}
            {isOpenModalFaltaAdicionarPagamento && (
                <div className='content-modal'>
                    <div className='modal-content'>
                        <h3>Pagamento adicionado automáticamente. Confirme os valores e clique em receber</h3>
                        <div className='botoes'>
                            <button className='confirmar' onClick={() => setIsFaltaAdicionarPagamento(false)}>Ok</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FecharMesa;
