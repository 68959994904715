import React, { Component } from 'react';
// import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'; 
import Header from './Header';
import Menu from './Menu';
import Login from './Login';
import Products from './Products';
import VerResumo from './VerResumo';
import ProdutoAdicionado from './ProdutoAdicionado';
import FechamentoCaixa from './FechamentoCaixa';

class Root extends Component {
  constructor(props) {
    super(props);
    const cachedLogin = localStorage.getItem('isLoggedIn') === 'true';
    this.state = {
      isLoggedIn: cachedLogin,
      exibirNovaTela: false,
    };
  }
  
  // Método onLogin
  onLogin = (login) => {
    const allowedCodes = ['4562', '4587', '2189', '7899', '5531', '2783', '1810'];
    // 4562 = Adriano
    // 4587 = Antonio
    // 2189 = Volmir
    // 7899 = Zeca
    // 5531 = Leandro
    // 2783 = Juan
    // 1810 = Michel 

    if (allowedCodes.includes(login)) {
        this.setState({ isLoggedIn: true }, () => {
        localStorage.setItem('isLoggedIn', true);
        localStorage.setItem('loginId', login);        
        localStorage.removeItem('produtos');
        localStorage.removeItem('idPedido');
        localStorage.removeItem('numeroMesa');
        localStorage.removeItem('idArquivo');
        localStorage.removeItem('status');
      });
    } else {      
      this.setState({ isLoggedIn: false }, () => {
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('loginId');        
        var elemento = document.querySelector('.error');
        elemento.style.display = 'block';
      });
    }
  };
  

  onButtonFecharCaixa = (login) => {    
    if (login === '5531' || login === '4562' || login === '4587' || login === '2189' || login === '7899'  || login === '2783' || login === '1810') {
      this.setState({ exibirNovaTela: true });
    }else{
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('loginId');        
      var elemento = document.querySelector('.error');
      elemento.style.display = 'block';
    }
  };

  componentDidMount() {
    // Bloqueia o uso dos botões "voltar" e "avançar" do navegador
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', this.onBackButtonEvent);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.onBackButtonEvent);
  }

  onBackButtonEvent = (e) => {
    e.preventDefault();
    window.history.pushState(null, null, window.location.pathname);
  };
  
  render() {
    
    const { isLoggedIn, exibirNovaTela } = this.state;
    
    if (exibirNovaTela) {
      return(
      <>        
       <FechamentoCaixa />
      </>
      )
    }

    if (!isLoggedIn) {      
      return <Login onLogin={this.onLogin} onButtonFecharCaixa={this.onButtonFecharCaixa} />;
    }
    

    const produtosLocalStorage = JSON.parse(localStorage.getItem('produtos'));
    const temProdutosLocalStorage = produtosLocalStorage && produtosLocalStorage.length > 0;

    const numeroMesa = localStorage.getItem("numeroMesa");
    const temProdutos = !!numeroMesa && temProdutosLocalStorage;

    return (
      <>
        <Header />
        <VerResumo />
        <Menu />      
        <Products />
        {temProdutos && <ProdutoAdicionado />}
      </>
    );
  }
}

export default withRouter(Root);
