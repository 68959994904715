import React from 'react'

import logo from '../../assets/img/1.png'

const PestiscosImage = () => (
  <div className='default-image'>
    <img src={logo}  alt='Pestiscos' style={{ width: '100%' }} />
  </div>
)

export default PestiscosImage