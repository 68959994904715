import React from 'react'

import logo from '../../assets/img/3.png'

const DestiladosImage = () => (
  <div className='default-image'>
    <img src={logo}  alt='Destilados' style={{ width: '100%' }} />
  </div>
)

export default DestiladosImage