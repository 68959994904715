import React, { useState, useEffect } from 'react';
import api from '../services/api.js';

const ListaMesasAbertas = () => {
  const [mesasAbertas, setMesasAbertas] = useState([]);
  const [mesaSelecionada, setMesaSelecionada] = useState(null);

  useEffect(() => {
    async function fetchMesasAbertas() {
      try {
        const response = await api.get('/mesa-pedido/mesas-abertas');
        const mesasArray = Object.values(response.data); // Converter o objeto em uma matriz de mesas
        setMesasAbertas(mesasArray);
      } catch (error) {
        console.error('Erro ao buscar as mesas abertas:', error);
      }
    }

    fetchMesasAbertas();
  }, []);

  const handleRowClick = (mesa) => {    
    setMesaSelecionada(mesa);        
  };

  return (
    <div>
      <div className='MesasAbertas'>
        <h2>Mesas Abertas</h2>
        <table>
          <thead>
            <tr>
              <th>Mesa</th>
              <th>Data de Abertura</th>
              <th>Quem Abriu</th>
            </tr>
          </thead>
          <tbody>
            {mesasAbertas.map(mesa => (
              <tr key={mesa.numero_mesa} onClick={() => handleRowClick(mesa)}>
                <td>{mesa.numero_mesa}</td>
                <td>{new Date(mesa.created_at).toLocaleString()}</td>
                <td>{mesa.quem_abriu}</td>
              </tr>
            ))}
          </tbody>
        </table>        
      </div>
      {mesaSelecionada && (        
        <div className="DetalhesMesa">          
          <table>
            <thead>
              <tr>
                <th>Usuário</th>
                <th>Qtd</th>
                <th>Produto</th>                                
              </tr>
            </thead>
            <tbody>
              {mesaSelecionada.mesa_consumo.map(item => (
                <tr key={item.id}>
                  <td>{item.quem_lancou}</td>
                  <td>{item.quantidade}</td>
                  <td>{item.texto_imprimir}</td>                  
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <button className="botao-voltar" onClick={() => window.location.href = '/'}>Voltar</button>
    </div>
  );
};

const FechamentoCaixa = () => {
  return (
    <div>
      <ListaMesasAbertas />
    </div>
  );
};

export default FechamentoCaixa;
