import React from 'react'

import logo from '../../assets/img/1.png'

const TequilaImage = () => (
  <div className='default-image'>
    <img src={logo}  alt='Tequila' style={{ width: '100%' }} />
  </div>
)

export default TequilaImage