import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import DefaultImage from '../placeholders/DefaultImage'
import PetiscosImage from '../placeholders/PetiscosImage'
import EntradasImage from '../placeholders/EntradasImage'
import SanduichesImage from '../placeholders/SanduichesImage'
import KidsImage from '../placeholders/KidsImage'
import FimDeAnoImage from '../placeholders/FimDeAnoImage'
import ParrillaImage from '../placeholders/ParrillaImage'
import AcompanhamentosImage from '../placeholders/AcompanhamentosImage'
import PratosImage from '../placeholders/PratosImage'
import SaladasImage from '../placeholders/SaladasImage'
import SobremesasImage from '../placeholders/SobremesasImage'
import BebidasImage from '../placeholders/BebidasImage'
import ClericotImage from '../placeholders/ClericotImage'
import SangriaImage from '../placeholders/SangriaImage'
import ClassicosImage from '../placeholders/ClassicosImage'
import { PriceLoader } from '../placeholders/PriceLoader'
import dots from '../../assets/img/dots.png'

class Default extends Component {
  renderPrice() {
    if (this.props.price) {
      return <p className='product-price'>R$ {this.props.price.toFixed(2)}</p>
    }

    return <PriceLoader />
  }

  isFirst = () => {
    if (this.props.prodKey === 0) {
      return true
    }

    return false
  }

  renderFirst = () => {
    if (this.isFirst() && !this.props.searchTerm.length) {
      return (
        <div className='product-category-title'>
          <div className='product-group-name'>{this.props.category}</div>
        </div>
      )
    }

    return <></>
  }
  
  render() {
    return (
      <>
        {this.renderFirst()}
        <div className={this.props.classes}>        
          <div className='product-image'>
            <Link className='no-decoration product-info' to={`/produto?id=${this.props.index}`}>
              {(() => {
                if (this.props.category === "Pestiscos") {
                  return <PetiscosImage />;
                } else if (this.props.category === "Entradas") {
                  return <EntradasImage />;
                } else if (this.props.category === "Sanduíches") {
                  return <SanduichesImage />;
                } else if (this.props.category === "Kids") {
                  return <KidsImage />;
                } else if (this.props.category === "Especial Fim de Semana") {
                  return <FimDeAnoImage />;
                } else if (this.props.category === "Parrilla") {
                  return <ParrillaImage />;
                } else if (this.props.category === "Acompanhamentos") {
                  return <AcompanhamentosImage />;
                } else if (this.props.category === "Pratos") {
                  return <PratosImage />;
                } else if (this.props.category === "Saladas") {
                  return <SaladasImage />;
                } else if (this.props.category === "Sobremesas") {
                  return <SobremesasImage />;
                } else if (this.props.category === "Bebidas") {
                  return <BebidasImage />;
                } else if (this.props.category === "Clericot") {
                  return <ClericotImage />;
                } else if (this.props.category !== "Sangria") {
                  return <SangriaImage />;
                } else  if (this.props.category === "Clássicos") {
                  return <ClassicosImage />;
                } else{
                  return <DefaultImage />;
                }
              })()}
            </Link>
          </div>
          <Link className='no-decoration product-info' to={`/produto?id=${this.props.index}`}>
            <p className='product-name'>{this.props.name}</p>
            <p className='product-description'>{this.props.description}</p>
            <div className='product-footer'>
              {this.renderPrice()}
              <img src={dots} alt='Abrir produto' />
            </div>
          </Link>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    searchTerm: state.productsReducer.searchTerm
  }
}

export default connect(mapStateToProps)(Default)