import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Root from './Root'
import ProductInfo from './ProductInfo'
import { getProducts } from '../store/actions/products'

class Routes extends Component {
  componentDidMount() {
    this.props.getProducts()
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route exact={true} path='/'>
            <Root />
          </Route>
          <Route path='/produto'>
            <ProductInfo />
          </Route>
        </Switch>
      </Router>
    )
  }
}

const mapStateToProps = state => {
  return {
    isFetching: state.productsReducer.isFetching,
    error: state.productsReducer.error
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getProducts: () => dispatch(getProducts())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes)