import React from 'react'

import logo from '../../assets/img/6.png'

const GinsImage = () => (
  <div className='default-image'>
    <img src={logo}  alt='Gins' style={{ width: '100%' }} />
  </div>
)

export default GinsImage