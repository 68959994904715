import React from 'react'

import logo from '../../assets/img/8.png'

const ChoppImage = () => (
  <div className='default-image'>
    <img src={logo}  alt='Chopp' style={{ width: '100%' }} />
  </div>
)

export default ChoppImage