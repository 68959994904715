import React, { useState } from 'react';
import logoLogin from '../assets/img/logo-loader.png';

const Login = ({ onLogin, onButtonFecharCaixa }) => {
  const [login, setLogin] = useState('');

  const handleChange = (event) => {
    const inputValue = event.target.value.replace(/\D/g, '');
    if (inputValue.length <= 4) {
      setLogin(inputValue);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onLogin(login);
  }; 

  const handleButtonFecharCaixa = (event) => {    
    event.preventDefault();    
    onButtonFecharCaixa(login);    
  };

  return (
    <div>
      <div className='login'>
        <img src={logoLogin} alt="Logo" />
        <form onSubmit={handleSubmit}>
          <span>Login</span>
          <input
            type="password"
            name='login'
            value={login}
            onChange={handleChange}
            maxLength={4}
          />
          <p className='error'>Código incorreto, ou sem acesso a essa função</p>          
          <button type='submit'>Acessar</button>
          <button type='button' onClick={handleButtonFecharCaixa}>Mesas Abertas</button>
        </form>
      </div>
    </div>
  );
}

export default Login;