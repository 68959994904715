import React from 'react'

import Categories from './Categories'
import Searchbar from './Searchbar'

const Menu = () => (
  <>    
    <Categories />
    <Searchbar numeroMesa={localStorage.getItem('numeroMesa')} />
  </>
)

export default Menu