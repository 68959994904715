import React from 'react'

import logo from '../../assets/img/7.png'

const EspumanteImage = () => (
  <div className='default-image'>
    <img src={logo}  alt='Espumante' style={{ width: '100%' }} />
  </div>
)

export default EspumanteImage