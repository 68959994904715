import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'; // Importa o withRouter
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class ProductOpened extends Component {
  state = {
    name: '',
    description: '',
    price: null,
    quantity: 1,    
    selectedOption: '',
    obsTextArea: '',
    produtoId: '',
    complemento1: '',
    complemento2: '',
    complemento3: '',
    complemento4: '',
    complemento5: '',
    complemento6: '',
    isOpenModalConfirmacao: false,
    isOpenModalErro: false 
  };

  componentDidMount() {    
    this.getProductInfo(parseInt(this.props.index, 10));
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', this.onBackButtonEvent);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.onBackButtonEvent);
  }

  onBackButtonEvent = (e) => {
    e.preventDefault();
    window.history.pushState(null, null, window.location.pathname);
  };
  getProductInfo = (index) => {
    let found = false;
    this.props.products.forEach((category) => {      
      category.items.forEach((item) => {
        if (item.id === index) {
          this.setState({
            name: item.name,
            description: item.description,
            complemento1: item.complemento1,
            complemento2: item.complemento2,
            complemento3: item.complemento3,
            complemento4: item.complemento4,
            complemento5: item.complemento5,
            complemento6: item.complemento6,
            price: this.getPrice(item.ncr_id),            
            produtoId: item.id
          });
          found = true;
        }
      });
    });
    if (!found) {
      window.location.href = '/'; 
    }
  };

  getPrice = (index) => {
    let price = this.props.prices.find((val) => val.id === index);

    if (price) {
      return price.price.toFixed(2);
    }

    return null;
  };

  addQuantity = () => {
    this.setState(prevState => ({
      quantity: prevState.quantity + 1
    }));
  };

  removeQuantity = () => {
    if (this.state.quantity > 1) {
      this.setState(prevState => ({
        quantity: prevState.quantity - 1
      }));
    }
  };

  handleOpcaoSelecionada = (textoOpcao) => {
    this.setState(prevState => ({
      obsTextArea: prevState.obsTextArea + textoOpcao + '\n',
      selectedOption: textoOpcao
    }));
  };

  
  limparTextArea = () => {
    this.setState({
      obsTextArea: ''
    });
  };
  

  adicionarProduto = async () => {
    const { quantity, name, produtoId, obsTextArea } = this.state;
    const mesaFromStorage = localStorage.getItem('numeroMesa');
        

    try {            
      const price = await this.getPrice(this.state.produtoId);
      const novoProduto = {
        mesa: mesaFromStorage,
        quantity: quantity,
        name: name,      
        price: price,
        produtoId: produtoId,      
        observacao: obsTextArea
      };
      
    
      let produtos = JSON.parse(localStorage.getItem('produtos') || '[]');
      produtos.push(novoProduto);
      localStorage.setItem('produtos', JSON.stringify(produtos));
      console.log("Novo produto:", novoProduto);
      
      this.setState({
        quantity: 1,
        price: null,
        produtoId: null,
        obsTextArea: ''
      });

      if (novoProduto) {
        window.location.href = '/';
      }      
    } catch (error) {
      alert("Erro ao adicionar produto:", error);
    }
  };

  render() {
    return (
      <div className='product-modal'>
        <div className='product-content'>
          <div className='product-details'>
            <div className='produto'>
              <div>
                <strong>{this.state.name}</strong> <br />
                <span>{this.state.description}</span>
                <hr />
                <span className='text-quantidade'>Quantidade</span>
                <div className='quantidade'>
                  <div className='menos' onClick={this.removeQuantity}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                      <path d="M10.5 0C8.4233 0 6.39323 0.615814 4.66652 1.76957C2.9398 2.92332 1.59399 4.5632 0.799269 6.48182C0.00454948 8.40044 -0.203386 10.5116 0.201759 12.5484C0.606904 14.5852 1.60693 16.4562 3.07538 17.9246C4.54383 19.3931 6.41476 20.3931 8.45156 20.7982C10.4884 21.2034 12.5996 20.9955 14.5182 20.2007C16.4368 19.406 18.0767 18.0602 19.2304 16.3335C20.3842 14.6068 21 12.5767 21 10.5C21 9.12112 20.7284 7.75574 20.2007 6.48182C19.6731 5.2079 18.8996 4.05039 17.9246 3.07538C16.9496 2.10036 15.7921 1.32694 14.5182 0.799265C13.2443 0.271591 11.8789 0 10.5 0ZM10.5 18.9C8.83864 18.9 7.21459 18.4073 5.83321 17.4843C4.45184 16.5613 3.37519 15.2494 2.73942 13.7145C2.10364 12.1796 1.93729 10.4907 2.26141 8.86124C2.58552 7.2318 3.38555 5.73506 4.56031 4.5603C5.73507 3.38554 7.2318 2.58552 8.86125 2.2614C10.4907 1.93729 12.1796 2.10364 13.7145 2.73941C15.2494 3.37519 16.5613 4.45184 17.4843 5.83321C18.4073 7.21458 18.9 8.83863 18.9 10.5C18.9 12.7278 18.015 14.8644 16.4397 16.4397C14.8644 18.015 12.7278 18.9 10.5 18.9ZM14.7 9.45H6.3C6.02153 9.45 5.75446 9.56062 5.55754 9.75753C5.36063 9.95445 5.25 10.2215 5.25 10.5C5.25 10.7785 5.36063 11.0455 5.55754 11.2425C5.75446 11.4394 6.02153 11.55 6.3 11.55H14.7C14.9785 11.55 15.2456 11.4394 15.4425 11.2425C15.6394 11.0455 15.75 10.7785 15.75 10.5C15.75 10.2215 15.6394 9.95445 15.4425 9.75753C15.2456 9.56062 14.9785 9.45 14.7 9.45Z" fill="#1D1D18"/>
                    </svg>
                  </div>
                  <div>
                    <input type="number" value={this.state.quantity} readOnly />
                  </div>
                  <div className='mais' onClick={this.addQuantity}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
                      <path d="M10.5219 21C7.72187 21 5.09687 19.95 3.08437 17.9375C-1.02812 13.825 -1.02812 7.175 3.08437 3.0625C5.09687 1.05 7.72187 0 10.5219 0C13.3219 0 15.9469 1.05 17.9594 3.0625C22.0719 7.175 22.0719 13.825 17.9594 17.9375C15.9469 19.95 13.3219 21 10.5219 21ZM10.5219 1.75C8.15937 1.75 5.97188 2.625 4.30938 4.2875C0.896875 7.7 0.896875 13.3 4.30938 16.7125C5.97188 18.375 8.15937 19.25 10.5219 19.25C12.8844 19.25 15.0719 18.375 16.7344 16.7125C20.1469 13.3 20.1469 7.7875 16.7344 4.375C15.0719 2.625 12.8844 1.75 10.5219 1.75Z" fill="#1D1D18"/>
                      <path d="M10.5219 15.75C9.99685 15.75 9.64685 15.4 9.64685 14.875V6.125C9.64685 5.6 9.99685 5.25 10.5219 5.25C11.0469 5.25 11.3969 5.6 11.3969 6.125V14.875C11.3969 15.4 11.0469 15.75 10.5219 15.75Z" fill="#1D1D18"/>
                      <path d="M14.8969 11.375H6.14685C5.62185 11.375 5.27185 11.025 5.27185 10.5C5.27185 9.975 5.62185 9.625 6.14685 9.625H14.8969C15.4219 9.625 15.7719 9.975 15.7719 10.5C15.7719 11.025 15.4219 11.375 14.8969 11.375Z" fill="#1D1D18"/>
                    </svg>
                  </div>
                </div>
                <hr />
                
                <span className='text-quantidade'>Opções</span>
                <div className='opcoes'>
                <ul className='opcao-esquerdo'>
                  {this.state.complemento1 && (
                    <li>
                      <button onClick={() => this.handleOpcaoSelecionada(this.state.complemento1)}>{this.state.complemento1}</button>
                    </li>
                  )}
                  {this.state.complemento3 && (
                    <li>
                      <button onClick={() => this.handleOpcaoSelecionada(this.state.complemento3)}>{this.state.complemento3}</button>
                    </li>
                  )}
                  {this.state.complemento5 && (
                    <li>
                      <button onClick={() => this.handleOpcaoSelecionada(this.state.complemento5)}>{this.state.complemento5}</button>
                    </li>
                  )}
                </ul>

                <ul className='opcao-direito'>
                  {this.state.complemento2 && (
                    <li>
                      <button onClick={() => this.handleOpcaoSelecionada(this.state.complemento2)}>{this.state.complemento2}</button>
                    </li>
                  )}
                  {this.state.complemento4 && (
                    <li>
                      <button onClick={() => this.handleOpcaoSelecionada(this.state.complemento4)}>{this.state.complemento4}</button>
                    </li>
                  )}
                  {this.state.complemento6 && (
                    <li>
                      <button onClick={() => this.handleOpcaoSelecionada(this.state.complemento6)}>{this.state.complemento6}</button>
                    </li>
                  )}
                </ul>

                </div>
                <hr />
                <div className='obs'>
                  <span className='text-quantidade'>Observações</span>
                  <svg className='limpar' onClick={this.limparTextArea} xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                    <path d="M12.0668 14.8335C12.0668 18.4071 11.2211 21.8129 10.2223 24.978H24.0558C25.2261 20.8501 24.978 17.6767 24.978 14.8335M12.0668 14.8335V12.989C12.0668 12.4998 12.2611 12.0307 12.607 11.6848C12.9529 11.3389 13.422 11.1445 13.9112 11.1445H23.1335C23.6227 11.1445 24.0919 11.3389 24.4378 11.6848C24.7837 12.0307 24.978 12.4998 24.978 12.989V14.8335M12.0668 14.8335H24.978M18.5224 11.1445V1" stroke="#1D1D18" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M21.2891 20.3669C21.2891 21.8267 20.7616 23.5107 19.8292 24.978M15.7557 20.3669C15.7557 21.8267 15.2282 23.5107 14.2958 24.978M2.84446 17.6002V21.2891M1 19.4446H4.68892M6.53339 10.2223V13.9112M4.68892 12.0668H8.37785" stroke="#1D1D18" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </div>
                <div className='textareaobs'>
                <textarea
                  name="obs"
                  id="obs"
                  cols="30"
                  rows="5"
                  value={this.state.obsTextArea}
                  onChange={(e) => this.setState({ obsTextArea: e.target.value })}
                ></textarea>
                </div>
                <hr />
                <div className='botoes-adicionar'>
                  <Link className='no-decoration product-info' to={`/`}>Cancelar</Link>
                  <button onClick={this.adicionarProduto}>
                    Adicionar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>       
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    products: state.productsReducer.products,
    prices: state.productsReducer.prices
  }
}

export default connect(mapStateToProps)(withRouter(ProductOpened));