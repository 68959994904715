import React, { useState, useEffect } from 'react';
import api from '../services/api.js';

const Header = () => {
  const [isOpenModalSemAcesso, setIsOpenModalSemAcesso] = useState(false);
  const [isOpenModalCaixaFechadoSucesso, setisOpenModalCaixaFechadoSucesso] = useState(false);
  const [isOpenModalGavetaAbertaSucesso, setisOpenModalGavetaAbertaSucesso] = useState(false);  


  const [numeroMesaInput, setNumeroMesaInput] = useState(() => {
    const mesaFromStorage = localStorage.getItem('numeroMesa');
    return mesaFromStorage || '';
  });

  const [numeroMesa, setNumeroMesa] = useState(() => {
    const mesaFromStorage = localStorage.getItem('numeroMesa');
    return mesaFromStorage || '';
  });

  const [idPedido, setIdPedido] = useState(() => {
    const idPedidoFromStorage = localStorage.getItem('idPedido');
    return idPedidoFromStorage || null;
  });

  const [idArquivo, setIdArquivo] = useState(() => {
    const idArquivoFromStorage = localStorage.getItem('idArquivo');
    return idArquivoFromStorage || null;
  });


  const [status] = useState(() => {
    const statusFromStorage = localStorage.getItem('status');
    return statusFromStorage || null;
  });

  useEffect(() => {
    localStorage.setItem('idPedido', idPedido);
  }, [idPedido]);

  useEffect(() => {    
    localStorage.setItem('numeroMesa', numeroMesa);
    localStorage.setItem('idPedido', idPedido);
    localStorage.setItem('idArquivo', idArquivo);
    // localStorage.setItem('loginId', loginId);
    
    const element = document.querySelector('.product-list');
    if (element) {
      if (numeroMesa.trim() !== '') {
        if (status !== "Fechada"){
           element.classList.remove('block-a');
           document.querySelector('.accordion').style.display = 'block';
        }else{
          
           document.querySelector('.accordion').style.display = 'block';
        }
      } else {
        element.classList.add('block-a');
        document.querySelector('.accordion').style.display = 'none';
      }
    }
  }, [numeroMesa,idPedido]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleModalButtonClickFechadoComSucesso = () => {        
    setisOpenModalCaixaFechadoSucesso(false);  
    setIsSubmitting(false);  
    window.location.href = '/';
  };

  const handleModalButtonClickGavetaAbertaSucesso = () => {        
    setisOpenModalGavetaAbertaSucesso(false);  
    setIsSubmitting(false);  
    window.location.href = '/';
  };
  

  const handleSubmit = async (event) => {    
    event.preventDefault();

    /* eslint-disable eqeqeq */
    if (numeroMesaInput == ''){
      localStorage.removeItem('numeroMesa');
      localStorage.removeItem('idPedido');
      localStorage.removeItem('idArquivo');            
      localStorage.removeItem('status');
      setIsSubmitting(false);      
      window.location.href = '/';
    }
    /* eslint-enable eqeqeq */
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Adiciona um zero à esquerda se for necessário
    const day = String(currentDate.getDate()).padStart(2, '0'); // Adiciona um zero à esquerda se for necessário
    
    const formattedDate = `${year}-${month}-${day}`;

    if (!isSubmitting && numeroMesaInput.trim() !== '') {
      setIsSubmitting(true);
        try {
          
          if (numeroMesaInput === '777') {
            const loginId = localStorage.getItem('loginId');
              if (loginId !== '7899' && loginId !== '5531' && loginId !== '4562' && loginId !== '1810') {
                setIsSubmitting(false);
                setIsOpenModalSemAcesso(true);
                // window.location.href = '/';  
                return;
              }
            await api.post('imprimir-geral', {
              quem_enviou: localStorage.getItem('loginId'),
              acao: 'abrir_gaveta',
              parametro: 'x'
            });                        
            localStorage.removeItem('numeroMesa');
            setisOpenModalGavetaAbertaSucesso(true);
          }else if (numeroMesaInput === '888') {
            await api.post('imprimir-geral', {
              quem_enviou: localStorage.getItem('loginId'),
              acao: 'fechar_caixa',
              parametro: `${localStorage.getItem('loginId')}|${formattedDate}`
            });                        
            setisOpenModalCaixaFechadoSucesso(true);
            localStorage.removeItem('numeroMesa');
            localStorage.removeItem('idPedido');
            localStorage.removeItem('idArquivo');
            localStorage.removeItem('loginId');
            localStorage.removeItem('isLoggedIn');
            localStorage.removeItem('status');
          }else if (numeroMesaInput === '999') {
            localStorage.removeItem('numeroMesa');
            localStorage.removeItem('idPedido');
            localStorage.removeItem('idArquivo');
            localStorage.removeItem('loginId');
            localStorage.removeItem('isLoggedIn');
            localStorage.removeItem('status');      
            setIsSubmitting(false);      
            window.location.href = '/';            
          }else{ 
            const response = await api.post('mesa-pedido', {
              numero_mesa: numeroMesaInput,
              quem_abriu: localStorage.getItem('loginId'),
              n_pessoas_mesa:'1',
              imprimir: false
            });          
            
            setIdPedido(response.data.id);
            setIdArquivo(response.data.id_arquivo);
            localStorage.setItem('status', response.data.status);
            setNumeroMesa(numeroMesaInput);            
            setIsSubmitting(false);
            window.location.href = '/';   
          }   
      } catch (error) {        
        setIsSubmitting(false);
        console.error('Erro ao verificar a mesa:', error);
        // Aqui você pode lidar com o erro de verificação da mesa
      }
    }
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    if (newValue.length === 0 || (newValue.length <= 3 && parseInt(newValue) >= 1)) {
      setNumeroMesaInput(newValue);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };  

  return (
    <header>
      <div className='mesa'>
        <form>
          <span>Mesa</span>
          <input 
            type="text" 
            inputMode="numeric"
            name="numero" 
            id="numero" 
            value={numeroMesaInput} 
            onChange={handleChange}
            onKeyDown={handleKeyPress}
            // onBlur={handleBlur}
            onClick={(e) => e.target.select()}
          />
          <button type="submit" onClick={handleSubmit}>Ok</button>
        </form>
      </div>
      <div className={status === "Fechada" || status === null ? 'numeromesa-fechada' : 'numeromesa'}>
        <p>{numeroMesa || ''}</p>
      </div>
      {isOpenModalSemAcesso && (
                <div className='content-modal'>
                    <div className='modal-content'>
                        <h3>Sem acesso para essa função</h3>
                        <div className='botoes'>
                            <button className='confirmar' onClick={() => setIsOpenModalSemAcesso(false)}>Ok</button>
                        </div>
                    </div>
                </div>
        )}
        {isOpenModalCaixaFechadoSucesso && (
                <div className='content-modal'>
                    <div className='modal-content'>
                        <h3>Caixa fechado com sucesso!</h3>
                        <div className='botoes'>
                            <button className='confirmar' onClick={handleModalButtonClickFechadoComSucesso}>Ok</button>
                        </div>
                    </div>
                </div>
        )}
        {isOpenModalGavetaAbertaSucesso && (
                <div className='content-modal'>
                    <div className='modal-content'>
                        <h3>Gaveta aberta com sucesso!</h3>
                        <div className='botoes'>
                            <button className='confirmar' onClick={handleModalButtonClickGavetaAbertaSucesso}>Ok</button>
                        </div>
                    </div>
                </div>
        )}
    </header>
  );
};

export default Header;
