import React from 'react'

import logo from '../../assets/img/4.png'

const SobremesasImage = () => (
  <div className='default-image'>
    <img src={logo}  alt='Sobremesas' style={{ width: '100%' }} />
  </div>
)

export default SobremesasImage