import React, { Component } from 'react'
import { connect } from 'react-redux'

import searchButton from '../assets/img/search-button.png'

import { setSearchTerm } from '../store/actions/products'

class Searchbar extends Component {
  render() {
    const { searchTerm, numeroMesa } = this.props

  
    return (
      <div className='searchbar'>              
        <div>
          <input type='text' name="term" onChange={e => this.props.setSearchTerm(e.target.value)} placeholder='Buscar no cardápio' value={searchTerm} />
          <img src={searchButton} className='searchbar-icon' alt='Buscar' />
        </div>
        <div className="searchbar-mesa">
          M: {numeroMesa}
        </div>
      </div>
      
    )
  }
}

const mapStateToProps = state => {
  return {
    searchTerm: state.productsReducer.searchTerm
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setSearchTerm: term => dispatch(setSearchTerm(term))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Searchbar)