import React from 'react'

import logo from '../../assets/img/4.png'

const BrancosImage = () => (
  <div className='default-image'>
    <img src={logo}  alt='Brancos' style={{ width: '100%' }} />
  </div>
)

export default BrancosImage