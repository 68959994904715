import React, { Component } from 'react'
import { connect } from 'react-redux'

import Product from './Product'

class Products extends Component {

  removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };
  
  getPrice = product_id => {
    let price = this.props.prices.filter(val => {
      return val.id === product_id
    })

    if (price.length) {
      return price[0].price
    }

    return false
  }

  renderProducts = () => {
    const { products, searchTerm } = this.props

    return products.map((product, catKey) => {
      return product.items.map((val, prodKey) => {
        if (searchTerm) {          
            if (this.removeAccents(val.name.toLowerCase()).includes(this.removeAccents(searchTerm.toLowerCase()))) {

            return <Product
              name={val.name}
              description={val.description}
              category={product.title}
              categoryType={product.category_type}
              catKey={catKey}
              prodKey={prodKey}
              price={this.getPrice(val.ncr_id)}
              key={val.id}
              options={val.options}
              index={val.id}
            />
          } else if (this.removeAccents(val.description.toLowerCase()).includes(this.removeAccents(searchTerm.toLowerCase()))) {

            return <Product
              name={val.name}
              description={val.description}
              category={product.title}
              categoryType={product.category_type}
              catKey={catKey}
              prodKey={prodKey}
              price={this.getPrice(val.ncr_id)}
              key={val.id}
              options={val.options}
              index={val.id}
            />
          } else {
            return <div key={val.id}></div>
          }
        } else {
          return <Product
            name={val.name}
            description={val.description}
            category={product.title}
            categoryType={product.category_type}
            catKey={catKey}
            prodKey={prodKey}
            price={this.getPrice(val.ncr_id)}
            key={val.id}
            options={val.options}
            index={val.id}
          />
        }
      })
    })
  }

  render() {
    return (
      <div className='product-list block-a'>
        {this.renderProducts()}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    products: state.productsReducer.products,
    prices: state.productsReducer.prices,
    searchTerm: state.productsReducer.searchTerm,
    category_id: state.tabReducer.category_id,
    tab_id: state.tabReducer.tab_id
  }
}

export default connect(mapStateToProps)(Products)