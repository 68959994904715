import React from 'react'

import logo from '../../assets/img/5.png'

const RosesImage = () => (
  <div className='default-image'>
    <img src={logo}  alt='Roses' style={{ width: '100%' }} />
  </div>
)

export default RosesImage