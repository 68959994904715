import React from 'react'

import logo from '../../assets/img/7.png'

const LongNeckImage = () => (
  <div className='default-image'>
    <img src={logo}  alt='Long Neck' style={{ width: '100%' }} />
  </div>
)

export default LongNeckImage