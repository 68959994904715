import React from 'react'

import logo from '../../assets/img/8.png'

const ClassicosImage = () => (
  <div className='default-image'>
    <img src={logo}  alt='Classicos' style={{ width: '100%' }} />
  </div>
)

export default ClassicosImage